body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: rgb(255, 197, 222);
  background: radial-gradient(
    circle,
    rgba(255, 197, 222, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#container {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.navbar {
  width: 100%;
  height: 80px;
  background: transparent;
  /* background: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid #b5b5b5; */
  backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 7%;
}
.content-wrapper {
  padding: 5% 7% 50px 7%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 100px;
}
.btn-mobile {
  display: none;
}
input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
  margin: none;
  color: #000;
  padding: 5px 10px;
}

#status {
  color: #254cdd;
  font-size: 14px;
  font-weight: 600;
}

button {
  padding: 9px 16px;
  max-height: 40px;
  box-shadow: 0 1px 34px -10px #000000;
  border-color: #254cdd;
  border: none;
  background: none;
  color: white;
  background-color: #254cdd;
  border-radius: 6px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  min-width: 160px;
  font-family: Rubik, sans-serif;
}

#logo {
  height: 50px;
  width: auto;
  object-fit: contain;
  margin-top: 20px;
}

#walletButton {
  padding: 7px 16px;
  float: right;
  border: 2px solid #000;
  border-radius: 20px;
  color: #000;
  margin-top: 20px;
  background: transparent;
  backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
h2 {
  display: block;
  clear: both;
  font-weight: 400;
}

a {
  color: #000;
}

.popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
}
.popup-bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid #b5b5b5;
  backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;
}
.popup-container {
  width: 100%;
  height: fit-content;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 30px;
  z-index: 102;
  padding: 50px 25px;
  background: #fff;
  box-shadow: 0 1px 34px -10px #000000;
}

.front {
  max-height: 700px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-shadow: 0 1px 34px -10px #000000;
}
@media screen and (max-width: 1000px) {
  .content-wrapper {
    padding: 50px 5%;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column-reverse;
  }
  .navbar {
    padding: 0 10px 0 0;
  }
  .btn-mobile {
    display: block;
    font-size: 14px;
  }
  .btn-desktop {
    display: none;
  }
  .front {
    margin: 0 auto; 
  }
  #logo{
    height: 40px;
  }
}